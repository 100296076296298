//
//

import { PDFDocument,
         StandardFonts } from 'pdf-lib'
import { getVFAParameters } from '~/utils/VFAParameters'

export default {
  name: 'FPCA-Form',
  data () {
    return {
      voter: {
        "identification": '',
        "votAdr": '',
        "voterClass": '',

        "salutation": '',
        "firstName":'',
        "middleName": '',
        "lastName": '',
        "suffix": '',
        "previousName": '',

        "dob": '',
        "birthDate": '',

        "classification": '',
        "ssn": '',
        "stateId": '',

        "votStreet": '',
        "votApt": '',
        "votCity": '',
        "votState": '',
        "votCounty": '',
        "votZip": '',

        "abrAdr": '',
        "fwdAdr": { A: '', formatted: [] },

        "email": '',
        "altEmail": '',
        "tel": '',
        "fax": '',
        "party": '',

        "recBallot": '',
      },

      isMilitary: '',
      isMilSpouse: '',
      isIntendToReturn: '',
      isUncertainReturn: '',
      isNeverResided: '',
      isMiss: '',
      isMrs: '',
      isMs: '',
      isMr: '',
      isReceiveBallotMail: '',
      isReceiveBallotEmail: '',
      isReceiveBallotFax: '',

      addlInfo: '',
      signatureDate: '',

      validCharSet: [],
      nonANSIsubstitutes: [
        {
          "src": "ʻ", /* okina */
          "sub": "‘"  /* raised inverted comma */
        }
      ],
    }
  },
  props: [ 'currentRequest',
           'additionalInfo',
           'date',
           'signatureImage',
           'dict',
           'parameters'
         ],
  mounted () {
    this.setupVoter ()
    this.createFPCAForm ()
  },
  watch: {
    signatureImage () {
      /*
        If the signature changes, re-render the FPCA.
        Reason: If the voter selects a submit option that does not require her name and then
        changes her mind, then we have to re-render the form.
      */
      this.createFPCAForm ()
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
  },
  methods: {
    setupVoter () {
      Object.keys(this.currentRequest).forEach(key => this.voter[key] = this.currentRequest[key])
      /**
       * 2023-01-21 John Yee
       * Some keys always exist in the currentRequest object e.g. tel, ssn, ssn4, ssn9, stateId.
       * If the voter has not entered anything, the value returned is null not '', the empty string.
       * We have to 'convert' the value to a string because pdf-lib will not render null.
       * pdf-lib requires a string.
       */

      const id = this.voter.identification
      this.voter.ssn = (id.ssn ? id.ssn : '') + (id.ssn4 ? id.ssn4 : '') + (id.ssn9 ? id.ssn9 : '')
      this.voter.stateId = id.stateId ? id.stateId : ''

      this.voter.votStreet = this.voter.votAdr.A
      this.voter.votApt = this.voter.votAdr.B
      this.voter.votCity = this.voter.votAdr.C
      this.voter.votState = this.voter.votAdr.S
      this.voter.votCounty = this.voter.votAdr.Y
      this.voter.votZip = this.voter.votAdr.Z

      // The fwdAdr.formatted array always contains the country.  If the street is empty,
      // then suppress rendering the forwarding address by setting the formatted array to empty.
      // That's because a valid address must have a non-empty street field.
      if (!this.voter.fwdAdr.A) {
        this.voter.fwdAdr.formatted = []
      }

      this.voter.tel = this.voter.tel ? this.voter.tel : ''

      this.voter.birthDate = this.voter.dob.slice(5, 7) + ' / ' + this.voter.dob.slice(8, 10) + ' / ' + this.voter.dob.slice(0, 4)

      this.isMilitary = this.voter.voterClass.toLowerCase() === 'military' ? 'X' : ''
      this.isMilSpouse = this.voter.voterClass.toLowerCase() === 'milspouse' ? 'X' : ''
      this.isIntendToReturn = this.voter.voterClass.toLowerCase() === 'intendtoreturn' ? 'X' : ''
      this.isUncertainReturn = this.voter.voterClass.toLowerCase() === 'uncertainreturn' ? 'X' : ''
      this.isNeverResided = this.voter.voterClass.toLowerCase() === 'neverresided' ? 'X' : ''

      this.isMiss = this.voter.salutation.toLowerCase() === 'miss' ? 'X' : ''
      this.isMrs = this.voter.salutation.toLowerCase() === 'mrs' ? 'X' : ''
      this.isMs = this.voter.salutation.toLowerCase() === 'ms' ? 'X' : ''
      this.isMr = this.voter.salutation.toLowerCase() === 'mr' ? 'X' : ''

      this.isReceiveBallotMail = this.voter.recBallot.toLowerCase() === 'mail' ? 'X' : ''
      this.isReceiveBallotEmail = this.voter.recBallot.toLowerCase() === 'email' ? 'X' : ''
      this.isReceiveBallotFax = this.voter.recBallot.toLowerCase() === 'fax' ? 'X' : ''

      this.addlInfo = this.additionalInfo

      this.signatureDate = this.date.slice(5, 7) + ' / ' + this.date.slice(8, 10) + ' / ' + this.date.slice(0, 4)
    },
    async createFPCAForm () {
      const pdfFormUrl = this.VFAParameters['FPCA_FORM_'+this.lang.toUpperCase()]
      const existingPDFFormBytes = await fetch(pdfFormUrl).then((res) => res.arrayBuffer())
      const pdfDoc = await PDFDocument.load(existingPDFFormBytes)

      const page = pdfDoc.getPages()[0] // The form appears on page one (the only page) of the pdf file.

      const fieldCoord = this.VFAParameters['FPCA_COORDS_'+this.lang.toUpperCase()]
      const normalFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
      const normalTextFontSize = 10 // 10 seems to be the default value for the form; determined by trial and error.
      const checkboxFontSize = 9 // for the "X" in a checkbox
      const fontScale = 0.9 // empirical value that gives the height of the rendered font as a fraction of the font size
      this.validCharSet = normalFont.getCharacterSet()
      this.nonANSIsubstitutes.forEach((el)=> {this.validCharSet.push((el.sub).codePointAt(0))})

      const writeLines = (field_coords, fieldText) => {
        /*
          fieldText may be too long to fit inside the input box;
          adjust the font size and wrap the text so all the text fits inside the field's input box
        */

        /*
          If the field's height can accomodate two lines of normal font size text, then set the initial
          font height to the normal font height, otherwise assume the field is normally a one line input
          field and set the initial font height to the height of the field.
          This looks nicer if the text has to wrap to a second line in a normally-one-line input box.

          The magic number 1.9 is simply an approximation to 2 i.e the field's height can accomodate
          two lines of normal font size text.
        */
        const initialFontSize = (field_coords.height/normalTextFontSize>1.9) ? normalTextFontSize : field_coords.height

        const newFieldLines = this.getLines(fieldText||'', normalFont, initialFontSize, field_coords)
        const fieldLines = newFieldLines.lines
        const fieldFontSize = newFieldLines.newFontSize

        for (var ii=fieldLines.length-1; ii>=0; ii--) {
          page.drawText(fieldLines[ii], { x: field_coords.x, y: field_coords.y+(fieldLines.length-1-ii)*fieldFontSize, size: fieldFontSize })
        }
      }

      const writeLinesArray = (field_coords, fieldTextArray) => {
        /*
          for an array of lines e.g. the abroad address
          an individual line may be too long to fit inside the input box;
          adjust the font size and wrap the text so all the text fits inside the field's input box
        */

        for (let ii=0; ii<fieldTextArray.length; ii++) {
          fieldTextArray[ii] = this.removeInvalidChars(fieldTextArray[ii])
        }

        /*
          If the field's height can accomodate two lines of normal font size text, then set the initial
          font height to the normal font height, otherwise assume the field is normally a one line input
          field and set the initial font height to the height of the field.
          This looks nicer if the text has to wrap to a second line in a normally-one-line input box.
        */
        const initialFontSize = (field_coords.height/normalTextFontSize>1.9) ? normalTextFontSize : field_coords.height

        let fieldFontSize = initialFontSize
        let initialNArrayLines = fieldTextArray.length
        let newNArrayLines = initialNArrayLines
        let newFieldLinesArray = []
        let linesHeight
        let linesAreOutsideBox

        // calculate the fieldFontSize that will allow all the lines to fit in the field's input box
        do {
          let totalLines = 0
          for (let ii=0; ii<fieldTextArray.length; ii++) {
            totalLines += (this.getLines(fieldTextArray[ii], normalFont, fieldFontSize, field_coords)).lines.length
          }

          linesHeight = totalLines*fieldFontSize*fontScale
          linesAreOutsideBox = linesHeight > field_coords.height
          
          // do not use the font size from getLines; rather revise the fieldFontSize "manually" by incrementing the number of lines in the box
          if (linesAreOutsideBox) {
            fieldFontSize = initialFontSize*(initialNArrayLines/++newNArrayLines)
          }
        }
        while (linesAreOutsideBox)

        // construct the array of all the lines, including the wrapped lines, using the calculated fieldFontSize
        for (let ii=0; ii<fieldTextArray.length; ii++) {
          let linesArray = this.getLines(fieldTextArray[ii], normalFont, fieldFontSize, field_coords)
          newFieldLinesArray = newFieldLinesArray.concat(linesArray.lines)
        }

        for (let ii=newFieldLinesArray.length-1; ii>=0; ii--) {
          page.drawText(newFieldLinesArray[ii], { x: field_coords.x, y: field_coords.y+(newFieldLinesArray.length-1-ii)*fieldFontSize, size: fieldFontSize })
        }
      }

      // Write data to the fields.

      page.drawText(this.isMilitary, { x: fieldCoord.activeDuty.x, y: fieldCoord.activeDuty.y, size: checkboxFontSize })
      page.drawText(this.isMilSpouse, { x: fieldCoord.spouseDependent.x, y: fieldCoord.spouseDependent.y, size: checkboxFontSize })
      page.drawText(this.isIntendToReturn, { x: fieldCoord.expatriateReturning.x, y: fieldCoord.expatriateReturning.y, size: checkboxFontSize })
      page.drawText(this.isUncertainReturn, { x: fieldCoord.expatriateUncertain.x, y: fieldCoord.expatriateUncertain.y, size: checkboxFontSize })
      page.drawText(this.isNeverResided, { x: fieldCoord.expatriateNeverLived.x, y: fieldCoord.expatriateNeverLived.y, size: checkboxFontSize })

      writeLines(fieldCoord.lastName, this.voter.lastName)
      writeLines(fieldCoord.firstName, this.voter.firstName)
      writeLines(fieldCoord.middleName, this.voter.middleName)
      writeLines(fieldCoord.suffix, this.voter.suffix)

      writeLines(fieldCoord.ssn, this.voter.ssn)

      page.drawText(this.isMiss, { x: fieldCoord.miss.x, y: fieldCoord.miss.y, size: checkboxFontSize })
      page.drawText(this.isMs, { x: fieldCoord.ms.x, y: fieldCoord.ms.y, size: checkboxFontSize })
      page.drawText(this.isMrs, { x: fieldCoord.mrs.x, y: fieldCoord.mrs.y, size:checkboxFontSize })
      page.drawText(this.isMr, { x: fieldCoord.mr.x, y: fieldCoord.mr.y, size: checkboxFontSize })

      writeLines(fieldCoord.previousName, this.voter.previousName.previousName)
      writeLines(fieldCoord.bday, this.voter.birthDate)
      writeLines(fieldCoord.stateId, this.voter.stateId)

      writeLines(fieldCoord.votStreet, this.voter.votStreet)
      writeLines(fieldCoord.votApt, this.voter.votApt)
      writeLines(fieldCoord.votCity, this.voter.votCity)
      writeLines(fieldCoord.votCounty, this.voter.votCounty)
      writeLines(fieldCoord.votState, this.voter.votState)
      writeLines(fieldCoord.votZip, this.voter.votZip)

      writeLinesArray(fieldCoord.abrAdr, this.voter.abrAdr.formatted)
      writeLinesArray(fieldCoord.fwdAdr, this.voter.fwdAdr.formatted)

      writeLines(fieldCoord.email, this.voter.email)
      writeLines(fieldCoord.altEmail, this.voter.altEmail)
      writeLines(fieldCoord.tel, this.voter.tel)
      writeLines(fieldCoord.fax, this.voter.fax)

      page.drawText(this.isReceiveBallotMail, { x: fieldCoord.recBallotMail.x, y: fieldCoord.recBallotMail.y, size: checkboxFontSize })
      page.drawText(this.isReceiveBallotEmail , { x: fieldCoord.recBallotEmail.x, y: fieldCoord.recBallotEmail.y, size: checkboxFontSize })
      page.drawText(this.isReceiveBallotFax, { x: fieldCoord.recBallotFax.x, y: fieldCoord.recBallotFax.y, size: checkboxFontSize })

      writeLines(fieldCoord.party, this.voter.party)

      writeLines(fieldCoord.addlInfo, this.addlInfo)

      writeLines(fieldCoord.todayDate, this.signatureDate)

      // Insert the image of the voter's signature in the signature box.
      if (this.signatureImage) {
        /**
         * 2024-01-09 John Yee
         * Box to add an image of the voter signature:
         *  The red-outline box is inside a taller png image.
         *  I created a signature image twice the height of the red-outline box.  The image has 
         *  a white border that is the same height above and below the red-outline box i.e.
         *  the red-outline box has symmetric padding above and below.
         *  That is the constant blankImageToBoxRatio and where the factor of 2 arises.
         * 
         *  The signature image must be shifted down so that the red-outline box of the signature
         *  coincides with the FPCA signature box.
         *  That is the constant heightOffset; which equals 1/4 the full height of the png image.
         */
        const blankImageToBoxRatio = 2

        const signaturePngImage = await pdfDoc.embedPng(this.signatureImage)
        const signatureRawDim = signaturePngImage.scale(1.0)
        const signatureScale = fieldCoord.signature.height / (signatureRawDim.height/blankImageToBoxRatio)
        const signaturePngImageDims = signaturePngImage.scale(signatureScale)
        const heightOffset = (signaturePngImageDims.height/(2*blankImageToBoxRatio))
        page.drawImage(signaturePngImage, {
          x: fieldCoord.signature.x,
          y: fieldCoord.signature.y - heightOffset,
          width: signaturePngImageDims.width,
          height: signaturePngImageDims.height,
        })
      }

      const pdfBytesForm = await pdfDoc.save()
      this.$emit('pdfBytesFpcaForm', pdfBytesForm)

      const pdfBytesFormBase64 = await pdfDoc.saveAsBase64({ dataUri: true })
      this.$emit('pdfBytesFpcaFormBase64', pdfBytesFormBase64)
    },
    getLines(longLine, font, initialFontSize, field_coords) {
      const field_width = field_coords.width
      const field_height = field_coords.height
      let   newFontSize = initialFontSize
      let   lines = this.getLinesFromLongline(longLine, font, newFontSize, 0, field_width)
      let   nLinesInField = Math.floor(field_height/newFontSize)

      while (lines.length > nLinesInField) {
        newFontSize = Math.ceil(field_height/++nLinesInField)
        lines = this.getLinesFromLongline(longLine, font, newFontSize, 0, field_width)
      }

      return { newFontSize, lines }
    },
    getLinesFromLongline(longLine, font, fontSize, leftMargin, rightMargin) {
      if (typeof longLine==="undefined"){
        return []
      }
      if (!longLine.trim()){
        return []
      }

      const bodyWidth = rightMargin-leftMargin
      let words = this.removeInvalidChars(longLine) 
          words = words.trim().replace(/\s+/g, " ").split(" ")
      let lines = []
      let lineLength
      let nextWord = ''
      let oldLine = ''

      while (words.length>0) {
        nextWord = words.shift()
        lineLength = font.widthOfTextAtSize(oldLine + nextWord + ' ', fontSize)

        if (lineLength>bodyWidth) {
          lines.push(oldLine.trim())
          oldLine = nextWord + ' ' // start a new oldLine with the nextWord as the first word
        } else {
          oldLine += nextWord + ' '
        }
      }

      // in case the last line is not blank, but ran out of words before the margin was exceeded
      if (oldLine.trim()!=='') {
        lines.push(oldLine.trim())
      }

      return lines
    },
    removeInvalidChars(lineOfText) {
      let newStringArray = []
      let newLineOfText = lineOfText

      this.nonANSIsubstitutes.forEach((el)=> {
        if (newLineOfText.includes(el.src)) {
          newLineOfText = newLineOfText.replaceAll(el.src,el.sub)
        }
      })

      newLineOfText.split('').forEach((ch) => {this.validCharSet.includes(ch.codePointAt(0)) ? newStringArray.push(ch) : newStringArray.push(' ')})
      return newStringArray.join('')
    },
  },
}
